export function loadTrustpilot(ref) {
	// remove the script if it already exists
	const existingScript = document.getElementById("trustpilot-script");
	if (existingScript) {
		existingScript.remove();
	}

	const tpScript = document.createElement("script");
	tpScript.type = "text/javascript";
	tpScript.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
	tpScript.async = true;
	tpScript.id = "trustpilot-script";
	document.head.appendChild(tpScript);
	tpScript.onload = function () {

		if (window.Trustpilot && ref.current) {
      const slider = ref.current.querySelector('.trustpilot-widget');
			window.Trustpilot.loadFromElement(slider, true);
		}
	};
}