import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import TestimonialsComponent from '../../components/testimonials';

const GlobalTestimonials = () => {
  const data = useStaticQuery(graphql`
    query TestimonialsDataQuery {
      contentfulComponentTestimonials(
        componentId: { eq: "Global Testimonials (Used on all other pages)" }
      ) 
      {
        hashId
        componentBackground
        testimonialsHeading: heading
        testimonialsSubheading: subheading
        trustpilotCode {
          trustpilotCode
        }
        testimonials {
          name
          stars
          date
          quote {
            quote
          }
          photo {
            gatsbyImage(width: 100, layout: FIXED, formats: PNG)
          }
          reviewsPlatform   
        }
      }
    }
  `);

  return (
    <>
    {data && <TestimonialsComponent data={data.contentfulComponentTestimonials}></TestimonialsComponent>}
    </>
  );
};

// GlobalTestimonials.propTypes = {
//   data: PropTypes.arrayOf(
//     PropTypes.shape({
//       childContentfulCommonTextTextRichTextNode: PropTypes.shape({
//         childContentfulRichText: PropTypes.objectOf(PropTypes.string)
//       })
//     })
//   )
// };
export default GlobalTestimonials;
